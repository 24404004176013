import React from "react"
import Helmet from "react-helmet"

const logoImg = require("../dist/images/logo.png")


class TermsOfUsePage extends React.Component {

    render() {

        return (

            <div>
                <div className="application">
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>Syarat dan Ketentuan</title>
                    </Helmet>
                </div>

                 <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
          
                    <a className="navbar-brand logo-image" href="https://www.trouverty.com"><img src={logoImg} alt="alternative" /></a>
                    

                    <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                        <ul className="navbar-nav ml-auto">
                        
                        </ul>

                        <span className="nav-item social-icons">
                        <span className="fa-stack">
                            <a href="https://www.facebook.com/trouverty/">
                            <i className="fas fa-circle fa-stack-2x facebook"></i>
                            <i className="fab fa-facebook-f fa-stack-1x"></i>
                            </a>
                        </span>
                        <span className="fa-stack">
                                <a href="https://www.instagram.com/trouverty">
                                    <i className="fas fa-circle fa-stack-2x instagram"></i>
                                    <i className="fab fa-instagram fa-stack-1x"></i>
                                </a>
                            </span>
                        </span>
                    </div>
                </nav>

                <div className="cards-1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2>Syarat dan Ketentuan</h2>
                                <p className="text-justify tab">Disarankan sebelum mengakses situs dan/atau aplikasi Trouverty (“Aplikasi”) maupun menggunakan setiap fitur dan/atau layanan yang tersedia di dalam aplikasi (“Layanan”) lebih jauh, Anda dimohon terlebih dahulu membaca dan memahami Syarat dan Ketentuan ini dan Kebijakan Privasi yang berlaku. Syarat dan ketentuan berikut adalah ketentuan dalam pengunjungan situs, isi dan/atau konten, layanan, serta fitur lainnya yang ada di <a className="turquoise" href="https://www.trouverty.com">www.trouverty.com</a>.</p>
                                <p className="text-justify tab">Dengan mengakses dan/atau menggunakan situs www.trouverty.com, informasi, atau aplikasi lainnya dalam bentuk aplikasi mobile yang disediakan oleh <strong>Trouverty Indonesia</strong> (<strong>“Trouverty”</strong> atau <strong>“trouverty.com”</strong> atau <strong>“Kami”</strong> atau <strong>“Milik Kami”</strong>), berarti Anda telah memahami dan menyetujui serta terikat dan tunduk dengan segala syarat dan ketentuan yang berlaku di Situs ini.</p>
                                <p className="text-justify tab">Trouverty Indonesia dapat setiap saat merevisi Syarat dan Ketentuan dengan memperbaharui Ketentuan & Persyaratan Privasi ini. Penggunaan layanan kami oleh Anda merupakan penerimaan Anda terhadap ketentuan-ketentuan tersebut. Apabila Anda memiliki keberatan atas salah satu dari Ketentuan Penggunaan kami atau pemberitahuan lain pada layanan kami satu-satunya pilihan Anda adalah untuk segera menghentikan penggunaan layanan atau platform kami oleh Anda.</p>
                                <p className="text-justify tab">Kami tidak menjamin bahwa konten, tautan, atau subdomain yang dimuat pada, atau berkaitan dengan situs web kami akan tersedia dan dapat diakses selalu oleh Anda. Kami dapat mengubah alur atau lokasi suatu tautan atau subdomain, atau mengarahkan ulang konten yang dimuat dalam suatu tautan atau subdomain pada situs web kami sewaktu-waktu tanpa pemberitahuan sebelumnya kepada Anda. Kami tidak menjamin bahwa suatu  tautan yang Anda teruskan akan tetap konstan pada waktu Anda meneruskan atau membaginya, karena tautan tersebut dapat berubah-ubah sewaktu-waktu tanpa pemberitahuan kepada Anda.</p>
                                <p className="text-left"><strong>Batas Tanggung Jawab</strong></p>
                                <ol className="ol-list">
                                    <li className="text-justify">
                                        <p>Trouverty Indonesia tidak bertanggung jawab atas kebenaran informasi, gambar dan keterangan, termasuk namun tidak terbatas pada perincian mengenai judul iklan, deskripsi, harga, alamat, nomor telepon yang diberikan oleh pemasang iklan. Anda disarankan untuk berhubungan langsung dengan pemasang iklan untuk memastikan informasi yang dicari.</p>
                                        <p>Setiap informasi yang dibuat oleh pemasang iklan di Situs atau aplikasi mobile Trouverty mengenai perusahaan, perorangan atau badan lainnya, dan/atau mengenai produk atau layanan mereka, bukan merupakan bentuk dukungan atau sokongan, ataupun mengimplikasikan dukungan atau sokongan, terhadap kualitas atau kelayakan dari perorangan, perusahaan atau badan tersebut, atau terhadap layanan atau produknya. Tanggung jawab isi dan/atau materi iklan yang dipasang oleh pemasang iklan merupakan tanggung jawab sepenuhnya dari pemasang ilkan.</p>
                                        <p>Trouverty Indonesia tidak memiliki hak milik atas iklan yang dipasang oleh pengguna, maupun terlibat dalam proses kesepakan, pembayaran, pengiriman serta proses purna jual di antara penjual dan pembeli. Perikatan untuk penyerahan barang atau jasa yang terjadi melalui Layanan atau sebagai hasil dari kunjungan dan keberhasilan penawaran yang diajukan oleh pengguna dibuat dengan bebas antara penjual dan pembeli.</p>
                                        <p>Informasi gambar dan keterangan lainnya yang terdapat atau diterbitkan dalam Situs ini atau aplikasi mobile Trouverty juga dapat mengandung ketidakakuratan atau kesalahan tipografis. Para pemasang iklan mungkin akan melakukan perubahan atau perbaikan, dan/atau memperbarui informasi yang tertera di dalam Situs atau aplikasi mobile Trouverty dari waktu ke waktu. Trouverty Indonesia tidak menanggung kewajiban untuk memperbaharui Materi yang telah menjadi kadaluwarsa atau tidak akurat.</p>
                                    </li>
                                    <li className="text-justify">Trouverty Indonesia tidak bertanggung jawab atas semua jaminan dan kondisi, termasuk implikasi dari jaminan, kualitas, kelayakan atas informasi yang disampaikan pemasang iklan. Dalam keadaan apapun, Trouverty Indonesia tidak bertanggung jawab atas kerugian, baik khusus, langsung, tidak langsung ataupun yang bersifat konsekuensial, maupun kerugian atau kerusakan apapun sebagai akibat dari kerugian atas pemakaian, data atau keuntungan, baik dalam tindakan perikatan, kelalaian atau tindakan kesalahan lainnya, yang muncul dari atau berkaitan dengan penggunaan atau kinerja dari informasi dan/atau gambar yang disediakan oleh pemasang iklan.</li>
                                    <li className="text-justify">Trouverty Indonesia tidak bertanggung jawab atau berkewajiban atas penyerahan barang atau jasa, termasuk kepatuhan pembeli dan pemasang iklan dengan ketentuan perundang-undangan yang berlaku di Republik Indonesia baik dalam hal penawaran ataupun penjualan barang dan jasa ataupun transaksi lainnya.</li>
                                    <li className="text-justify">Pengguna memahami dan menyetujui bahwa penggunaan dan pelaksanaan kegiatan sehubungan dengan Situs dan/atau Aplikasi dan Layanan oleh pengguna adalah atas kebijakan dan risiko pengguna sendiri dan bahwa pengguna sendiri bertanggung jawab penuh atas materi pengguna, dan/atau atas kerusakan/kehilangan atas sistem elektronik Anda dan/atau kerusakan/kehilangan atas informasi elektronik yang mungkin saja diakibatkan oleh pelaksanaan kegiatan tersebut.</li>
                                    <li className="text-justify">Pengguna secara khusus mengakui bahwa Trouverty Indonesia tidak akan bertanggung jawab atas Materi atau tindakan pencemaran nama baik, tindakan yang melanggar, atau tindakan yang melawan hukum apapun dari pihak ketiga manapun yang berhubungan dengan Trouverty Indonesia dan Layanan ini. Segala resiko atas kerugian atau kerusakan dari hal-hal tersebut adalah seluruhnya tanggung jawab pengguna.</li>
                                    <li className="text-justify">Trouverty Indonesia tidak bertanggung jawab atas akibat langsung atau tidak langsung dari keputusan pengguna/calon pembeli dalam mengajukan penawaran atau tidak mengajukan penawaran kepada pemasang iklan, melakukan jual beli atau tidak melakukan jual beli dengan pemasang iklan.</li>
                                </ol>
                                
                            
                                <p className="text-left"><strong>Hak Cipta</strong></p>
                                <ol className="ol-list">
                                    <li className="text-justify">Trouverty merupakan nama dan logo, Aplikasi dan Layanan, dilindungi oleh hak cipta, merek dagang dan hak-hak lain yang disediakan berdasarkan hukum Negara Republik Indonesia. Kami secara eksklusif memiliki semua hak, kepemilikan hak kekayaan intelektual atas seluruh kode sumber (source code) Aplikasi dan kepentingan dalam dan terhadap Aplikasi, termasuk semua hak kekayaan intelektual terkait.</li>
                                    <li className="text-justify">Tanpa menyimpang dari hak kami berdasarkan hukum yang berlaku atau Syarat dan Ketentuan, Anda diberitahukan bahwa setiap berusaha atau pelanggaran nyata atas ketentuan ini akan mengakibatkan penghentian semua hak Anda di bawah Syarat dan Ketentuan. Jika Anda menghindari salah satu cara yang kami ambil untuk melindungi layanan dari penggunaan secara tidak sah, Anda harus segera menghentikan setiap dan semua penggunaan Layanan, dan Anda setuju untuk melakukannya.</li>

                                </ol>
                
                                <p className="text-left"><strong>Kontak Kami</strong></p>
                                <p className="text-justify">Jika Anda memiliki pertanyaan berkaitan dengan syarat dan ketentuan Trouverty Indonesia, silakan menghubungi kami di <a className="turquoise" href="mailto:support@trouverty.com">support@trouverty.com.</a></p>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <p className="p-small">Copyright © 2019 Trouverty</p>
                        </div>
                        </div>
                    </div>
                </div>

            </div>

            
        )
    }
}

export default TermsOfUsePage